.content
	
	a:not(.button)
		color: $black
		border-bottom: 1px solid #dbdbdb

		&:hover
			color: $red
			border-bottom: 1px solid $red

		&:visited,&:active,&:focus
			color: $black
			border-bottom: 1px solid #dbdbdb
