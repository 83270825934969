.button.is-primary
	color: $white
	background: $red

	&:hover, &:active, &:focus, &.is-active
		background: lighten($red, 10)

	&.is-outlined
		border-color: $red
		color: $red

		&:hover, &:active, &:focus, &.is-active
			background: $red
			border-color: $red