.media

	.content

		a:not(.button)
			color: $grey

			&:hover
				color: $red
				border-color: $red

			&:visited &:active
				color: $red