.card.categorie
	border-bottom: 3px solid $red

.card

	.card-content.annonce
		height: 145px
		display: flex
		flex-direction: column
		justify-content: space-between

		a
			display: block
			text-align: center
			transition(color .5s ease)

			&:hover
				text-decoration: none !important
				color: $red

		.informations
			display: flex
			font-size: 0.8em
			justify-content: space-between

			.date
				font-style: italic