.Bandeau
	padding: 10px 0

	&.is-grey
		color: $white
		background: $grey

	.container
		display: flex
		justify-content: center
		align-items: center

		@media screen and (max-width: 600px)
			flex-direction: column

			.texte
				text-align: center

		.texte
			margin: 0
			font-size: 1.4em
			font-weight: bold

		*
			margin-left: 20px
			margin-right: 20px