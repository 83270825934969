border-radius(n)
	-webkit-border-radius n
	-moz-border-radius n
	border-radius n

transition(n)
	-moz-transition: n
	-o-transition: n
	-webkit-transition: n
	transition: n

box-shadow(arguments)
	-moz-box-shadow: arguments
	-webkit-box-shadow: arguments
	box-shadow: arguments
