@require "_variables"
@require "_mixins"
@require "_bulma_hack"
@require "components/buttons"
@require "components/header"
@require "components/footer"
@require "components/card"
@require "components/bandeau"
@require "components/tabs"
@require "components/pub"
@require "components/media"
@require "components/content"

html, body
	height: 100%vh
	min-height: 100%vh

body
	font-family: 'Roboto', sans-serif

a
	color: inherit

p
	margin-bottom: 8px

.grey
	color: $grey

.red
	color $red

.white
	color: $white

.section
	background-color: inherit;

.has-text-justified
	text-align: justify

h3.categorie
	font-size: 1.6em
	font-weight: 500

.is-fullheight
	height: 100%

.title.no-margin
	margin-bottom: 2px

.title.is-primary
	color: $red
	text-transform: uppercase

.m-b-20
	margin-bottom: 20px

.flex
	display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;      /* TWEENER - IE 10 */
	display: -webkit-flex;     /* NEW - Chrome */
	display: flex

	&>*
		margin: 0 5px

.box.is-fullwidth
	width: 100%

.account
	margin-top: 25px
	background: $red
	color: $white

	&.no-mt
		margin-top: 0

img.magazine
	resize: both
	max-width: 250px

.hidden
	display: none
