.pub
	display: flex
	height: 100%

	a
		display: flex;
		align-items: flex-end;
		height: 100%

		&:hover img
			opacity: 0.85

	img
		width: 100%
		resize: both
		transition(opacity 0.75s ease)
		-webkit-backface-visibility: hidden;
