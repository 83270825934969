.input:active, .input:focus, .input.is-active,
.textarea:active,
.textarea:focus,
.textarea.is-active 
	border-color: $red
	outline: none

.select select:active, .select select:focus, .select select.is-active
	border-color: $red
	outline: none

.select:after
	border: 1px solid $red
	border-right: 0
	border-top: 0
	content: " "
	display: block
	height: 7px
	pointer-events: none
	position: absolute
	-webkit-transform: rotate(-45deg)
	      transform: rotate(-45deg)
	width: 7px
	margin-top: -6px
	right: 16px
	top: 50%

.select:hover:after
	border-color: #363636