.Header
	display: flex
	flex-direction: column

.Header__top
	background: $grey
	border-top: $borders solid $red
	padding: 12px 0
	color: $white
	font-size: 14px
	font-weight: bold
	margin-bottom: 20px

	.container
		display: flex
		justify-content: space-between

		.left
			display: flex
			align-items: center

			*
				margin-right: 5px

.Header__center

	.container
		display: flex
		justify-content: space-between
		align-items: center

		.Header__search
			margin-left: 20px

			.recherche
				padding: 15px
				color: $white
				background: $grey
				border-radius(8px)
				align-self: center

				p
					margin-bottom: 8px
					font-size: 15px

				form
					display: flex
					flex-direction: column
					
					.inputs
						display: flex

						input, select
							margin: 0 5px

					button[type=submit]
						font-weight: bold
						margin-top: 10px
						padding: 0 30px
						text-align: center

			h3
				align-self: flex-end
				color: $red
				font-size: 24px

.Header__nav
	margin-top: 15px
	background: $grey
	border-bottom: $borders solid $red

	ul
		display: flex
		justify-content: space-between
		flex-wrap: wrap
		align-self: center

		li
			display: flex
			flex: 1
			align-items: center
			font-size: 15px
			font-weight: bold
			color: $white
			border-right: 1px solid $light-grey
			transition(background .5s ease)

			&.nav--home
				flex: 0
				background: $red
				border-right: none

			&:hover
				background: $red

			a
				width: 100%
				padding: 10px 15px
				text-align: center
				color: $white