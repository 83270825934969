.Footer
	background: $grey
	border-top: $borders solid $lighter-grey

	.Footer__Bandeau
		height: $borders
		background: $red

	.Footer__Informations
		padding 10px 0

		.container
			display: flex
			justify-content: space-between

			div
				align-self: center
				color: $dark-white

				h4
					color: $white
					font-size: 1.3em
					text-align: center
					text-transform: uppercase
					font-weight: bold
					margin-bottom: 20px

					.border-bottom
						border-bottom: 2px solid $light-black

				li
					padding: 3px
					font-size: 15px

					a > i
						margin-right: 10px

					a:hover
						color: $white

			.Footer__Marque
				width: 300px
				align-self: flex-start

				p
					text-align: justify

	.Footer__copyright
		padding: 8px 0
		background: $dark-grey
		color: $dark-white

		.container
			display: flex
			justify-content: space-between
			align-items: center