.input:active,
.input:focus,
.input.is-active,
.textarea:active,
.textarea:focus,
.textarea.is-active {
  border-color: #bc162e;
  outline: none;
}
.select select:active,
.select select:focus,
.select select.is-active {
  border-color: #bc162e;
  outline: none;
}
.select:after {
  border: 1px solid #bc162e;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 7px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 7px;
  margin-top: -6px;
  right: 16px;
  top: 50%;
}
.select:hover:after {
  border-color: #363636;
}
.button.is-primary {
  color: #fff;
  background: #bc162e;
}
.button.is-primary:hover,
.button.is-primary:active,
.button.is-primary:focus,
.button.is-primary.is-active {
  background: #e5203d;
}
.button.is-primary.is-outlined {
  border-color: #bc162e;
  color: #bc162e;
}
.button.is-primary.is-outlined:hover,
.button.is-primary.is-outlined:active,
.button.is-primary.is-outlined:focus,
.button.is-primary.is-outlined.is-active {
  background: #bc162e;
  border-color: #bc162e;
}
.Header {
  display: flex;
  flex-direction: column;
}
.Header__top {
  background: #333;
  border-top: 8px solid #bc162e;
  padding: 12px 0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}
.Header__top .container {
  display: flex;
  justify-content: space-between;
}
.Header__top .container .left {
  display: flex;
  align-items: center;
}
.Header__top .container .left * {
  margin-right: 5px;
}
.Header__center .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Header__center .container .Header__search {
  margin-left: 20px;
}
.Header__center .container .Header__search .recherche {
  padding: 15px;
  color: #fff;
  background: #333;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  align-self: center;
}
.Header__center .container .Header__search .recherche p {
  margin-bottom: 8px;
  font-size: 15px;
}
.Header__center .container .Header__search .recherche form {
  display: flex;
  flex-direction: column;
}
.Header__center .container .Header__search .recherche form .inputs {
  display: flex;
}
.Header__center .container .Header__search .recherche form .inputs input,
.Header__center .container .Header__search .recherche form .inputs select {
  margin: 0 5px;
}
.Header__center .container .Header__search .recherche form button[type=submit] {
  font-weight: bold;
  margin-top: 10px;
  padding: 0 30px;
  text-align: center;
}
.Header__center .container .Header__search h3 {
  align-self: flex-end;
  color: #bc162e;
  font-size: 24px;
}
.Header__nav {
  margin-top: 15px;
  background: #333;
  border-bottom: 8px solid #bc162e;
}
.Header__nav ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-self: center;
}
.Header__nav ul li {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  border-right: 1px solid #555;
  -moz-transition: background 0.5s ease;
  -o-transition: background 0.5s ease;
  -webkit-transition: background 0.5s ease;
  transition: background 0.5s ease;
}
.Header__nav ul li.nav--home {
  flex: 0;
  background: #bc162e;
  border-right: none;
}
.Header__nav ul li:hover {
  background: #bc162e;
}
.Header__nav ul li a {
  width: 100%;
  padding: 10px 15px;
  text-align: center;
  color: #fff;
}
.Footer {
  background: #333;
  border-top: 8px solid #e6e6e6;
}
.Footer .Footer__Bandeau {
  height: 8px;
  background: #bc162e;
}
.Footer .Footer__Informations {
  padding: 10px 0;
}
.Footer .Footer__Informations .container {
  display: flex;
  justify-content: space-between;
}
.Footer .Footer__Informations .container div {
  align-self: center;
  color: #8c8c8c;
}
.Footer .Footer__Informations .container div h4 {
  color: #fff;
  font-size: 1.3em;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
}
.Footer .Footer__Informations .container div h4 .border-bottom {
  border-bottom: 2px solid #1e1e1e;
}
.Footer .Footer__Informations .container div li {
  padding: 3px;
  font-size: 15px;
}
.Footer .Footer__Informations .container div li a > i {
  margin-right: 10px;
}
.Footer .Footer__Informations .container div li a:hover {
  color: #fff;
}
.Footer .Footer__Informations .container .Footer__Marque {
  width: 300px;
  align-self: flex-start;
}
.Footer .Footer__Informations .container .Footer__Marque p {
  text-align: justify;
}
.Footer .Footer__copyright {
  padding: 8px 0;
  background: #2e2e2e;
  color: #8c8c8c;
}
.Footer .Footer__copyright .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card.categorie {
  border-bottom: 3px solid #bc162e;
}
.card .card-content.annonce {
  height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card .card-content.annonce a {
  display: block;
  text-align: center;
  -moz-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  -webkit-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.card .card-content.annonce a:hover {
  text-decoration: none !important;
  color: #bc162e;
}
.card .card-content.annonce .informations {
  display: flex;
  font-size: 0.8em;
  justify-content: space-between;
}
.card .card-content.annonce .informations .date {
  font-style: italic;
}
.Bandeau {
  padding: 10px 0;
}
.Bandeau.is-grey {
  color: #fff;
  background: #333;
}
.Bandeau .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .Bandeau .container {
    flex-direction: column;
  }
  .Bandeau .container .texte {
    text-align: center;
  }
}
.Bandeau .container .texte {
  margin: 0;
  font-size: 1.4em;
  font-weight: bold;
}
.Bandeau .container * {
  margin-left: 20px;
  margin-right: 20px;
}
.tabs.is-toggle li.is-active a {
  background-color: #bc162e;
  border-color: #bc162e;
}
.pub {
  display: flex;
  height: 100%;
}
.pub a {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.pub a:hover img {
  opacity: 0.85;
}
.pub img {
  width: 100%;
  resize: both;
  -moz-transition: opacity 0.75s ease;
  -o-transition: opacity 0.75s ease;
  -webkit-transition: opacity 0.75s ease;
  transition: opacity 0.75s ease;
  -webkit-backface-visibility: hidden;
}
.media .content a:not(.button) {
  color: #333;
}
.media .content a:not(.button):hover {
  color: #bc162e;
  border-color: #bc162e;
}
.media .content a:not(.button):visited .media .content a:not(.button):active {
  color: #bc162e;
}
.content a:not(.button) {
  color: #000;
  border-bottom: 1px solid #dbdbdb;
}
.content a:not(.button):hover {
  color: #bc162e;
  border-bottom: 1px solid #bc162e;
}
.content a:not(.button):visited,
.content a:not(.button):active,
.content a:not(.button):focus {
  color: #000;
  border-bottom: 1px solid #dbdbdb;
}
html,
body {
  height: 100vh;
  min-height: 100vh;
}
body {
  font-family: 'Roboto', sans-serif;
}
a {
  color: inherit;
}
p {
  margin-bottom: 8px;
}
.grey {
  color: #333;
}
.red {
  color: #bc162e;
}
.white {
  color: #fff;
}
.section {
  background-color: inherit;
}
.has-text-justified {
  text-align: justify;
}
h3.categorie {
  font-size: 1.6em;
  font-weight: 500;
}
.is-fullheight {
  height: 100%;
}
.title.no-margin {
  margin-bottom: 2px;
}
.title.is-primary {
  color: #bc162e;
  text-transform: uppercase;
}
.m-b-20 {
  margin-bottom: 20px;
}
.flex {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
}
.flex>* {
  margin: 0 5px;
}
.box.is-fullwidth {
  width: 100%;
}
.account {
  margin-top: 25px;
  background: #bc162e;
  color: #fff;
}
.account.no-mt {
  margin-top: 0;
}
img.magazine {
  resize: both;
  max-width: 250px;
}
.hidden {
  display: none;
}
